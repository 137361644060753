$(function() {
    $(document).foundation();

    $("#bmi-form").submit(function() {
        var weight  = parseFloat($('#bmi-weight').val());
        var feet    = parseInt($('#bmi-height-feet').val());
        var inches  = parseInt($('#bmi-height-inches').val());
        var height  = parseInt((feet * 12)) + parseInt(inches);
        var bmi     = (weight / Math.pow(height, 2)) * 703;

        var size = "Underweight";

        if(bmi >= 18.5) {
            size = "Normal";
        }

        if(bmi >= 25) {
            size = "Overweight";
        }

        if(bmi >= 30) {
            size = "Obese";
        }

        if(bmi >= 35) {
            size = "Severely Obese";
        }

        if(bmi >= 40) {
            size = "Morbidly Obese";
        }

        $('#bmi-number').html(bmi.toFixed(2) +  " <span> : " + size + "</span>");
        $('#bmi-calculator').addClass('show-results');
        $('#bmi-result').show();
        $('#calculate-bmi').blur();

        window.setTimeout(function() { $('#bmi-result').css('opacity', 1); }, 50);

        return false;
    });

    $('#bmi-calculate-again').click(function() {
        $('#bmi-weight').val('');
        $('#bmi-height-feet').val('');
        $('#bmi-height-inches').val('');

        $('#bmi-result').css('opacity', 0);

        window.setTimeout(function() {
            $('#bmi-calculator').removeClass('show-results');
            $('#bmi-result').hide();
        }, 200);
    });

    function parseOpts($el) {
        var opts = {};

        if($el.attr('data-options')){
            var thing = $el.attr('data-options').split(';').forEach(function(e, i){
                var opt = e.split(':').map(function(el){ return el.trim(); });
                if(opt[0]) opts[opt[0]] = parseValue(opt[1]);
            });
        }

        return opts;
    }

    function parseValue(str){
        if(/true/.test(str)) return true;
        else if(/false/.test(str)) return false;
        else if(!isNaN(str * 1)) return parseFloat(str);
        return str;
    }

    $('#blog-category').change(function(e) {
        window.location = $('#blog-category option:selected').val();
    });
});

$(window).load(function () {
    $('#loader').fadeOut();
});

